export const API_HOST = process.env.REACT_APP_API_HOST || "http://127.0.0.1:8090";

export const ROUTE_PATH = new Map([
  ["base64", "BASE64 加密"],
  ["url-encode", "URL 编码"],
  ["vmess", "Clash 转 VMess"],
  ["ocr", "图片转文字"],
  ["qrcode", "二维码生成"],
  ["qrcode-reader", "二维码识别"],
  ["rate-units", "码率单位转换"],
  ["jsdelivr", "Github To Jsdelivr"],
  ["https://sub-web-024.pages.dev/", "sub-web"],
  ["http://yacd.haishan.me/", "YACD"],
  ["https://gallenhu.github.io/myip/", "本机IP"],
  ["https://deershare.com", "小鹿快传"]
]);

export const HOME_MENU_LIST = Array.from(ROUTE_PATH.keys()).map(key => ({
  title: ROUTE_PATH.get(key),
  path: key.startsWith("http") ? key : `/${key}`,
}));
