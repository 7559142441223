import { API_HOST } from "../constants";

export async function ocrRead(password: string, image: string) {
  return fetch(`${API_HOST}/api/ocr`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ password, image }),
  }).then(res => res.json());
}
