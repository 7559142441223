import { Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";
import "./Tool.scss";
import { useEffect, useState } from "react";
import { ROUTE_PATH } from "../../constants";

export default function Tools() {
  const { pathname } = useLocation();
  const [pageName, setPageName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const pageName = ROUTE_PATH.get(pathname.substring(1)) || "Tools";
    setPageName(pageName);
    document.title = pageName;
  }, [pathname]);

  return (
    <section className="tools">
      <div className="tools-header">
        <span className="back" onClick={() => navigate(-1)}>
          &lt;
        </span>

        <Breadcrumb className="bc">
          <Breadcrumb.Item className="bc-item">
            <Link className="bc-item-link" to="/">
              首页
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="bc-item">
            <span className="bc-item-link">{pageName}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      {/*路由内容*/}
      <Outlet />
    </section>
  );
}
