import { Link } from "react-router-dom";
import "./Home.scss";
import { useEffect } from "react";
import { HOME_MENU_LIST } from "../../constants";

export default function Home() {
  useEffect(() => {
    document.title = "在线工具箱";
  }, []);

  return (
    <section className="home">
      <h1>在线工具箱</h1>

      <ul className="home-list">
        {HOME_MENU_LIST.map(item => (
          <li key={item.path}>
            {item.path.startsWith("http") ? (
              <a className="home-list--item" href={item.path} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            ) : (
              <Link className="home-list--item" to={item.path}>
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
}
