import { useState } from "react";
import { Input, Button, Space } from "antd";

const { TextArea } = Input;

export default function URLEncode() {
  const [srcText, setSrcText] = useState("");
  const [encText, setEncText] = useState("");

  const handleEncode = () => {
    if (!srcText.trim()) return;

    setEncText(encodeURIComponent(srcText.trim()));
  };
  const handleDecode = () => {
    if (!encText.trim()) return;

    setSrcText(decodeURIComponent(encText.trim()));
  };
  const handleChangeSrcText = (e: any) => {
    setSrcText(e.target.value);
  };
  const handleChangeEncText = (e: any) => {
    setEncText(e.target.value);
  };

  return (
    <>
      <div className="two-col">
        <div>
          <TextArea rows={10} value={srcText} placeholder="编码前" spellCheck={false} onChange={handleChangeSrcText} />
        </div>
        <div>
          <TextArea rows={10} value={encText} placeholder="编码后" spellCheck={false} onChange={handleChangeEncText} />
        </div>
      </div>
      <div className="two-col-btn">
        <Space>
          <Button type="primary" onClick={handleEncode}>
            编码
          </Button>
          <Button onClick={handleDecode}>解码</Button>
        </Space>
      </div>
    </>
  );
}
