import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import Home from "./pages/Home";
import ToolsLayout from "./pages/ToolsLayout";
import NotFound from "./pages/NotFound";
import Base64 from "./pages/Base64";
import URLEncode from "./pages/URLEncode";
import OCR from "./pages/OCR";
import VMess from "./pages/VMess";
import QRCode from "./pages/QRCode";
import QRCodeReader from "./pages/QRCode/Reader";
import RateUnits from "./pages/RateUnits";
import Jsdelivr from "./pages/Jsdelivr";

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "/*",
    element: <ToolsLayout />,
    children: [
      {
        path: "base64",
        element: <Base64 />,
      },
      {
        path: "url-encode",
        element: <URLEncode />,
      },
      {
        path: "ocr",
        element: <OCR />,
      },
      {
        path: "vmess",
        element: <VMess />,
      },
      {
        path: "qrcode",
        element: <QRCode />,
      },
      {
        path: "qrcode-reader",
        element: <QRCodeReader />,
      },
      {
        path: "rate-units",
        element: <RateUnits />,
      },
      {
        path: "jsdelivr",
        element: <Jsdelivr />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
