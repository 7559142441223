import { useEffect, useState } from "react";
import { Input, Upload, message, Space, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { RcFile, UploadProps } from "antd/es/upload/interface";
import { ocrRead } from "../../service/ocr.api";
import Storage from "../../service/storage";

const { TextArea } = Input;
const { Dragger } = Upload;

export default function OCR() {
  const [messageApi] = message.useMessage();

  const [password, setPassword] = useState("");
  const [base64Text, setBase64Text] = useState("");
  const [outText, setOutText] = useState("");

  useEffect(() => {
    const pwd = Storage.getOcrPassword();
    pwd && setPassword(pwd);
  }, []);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
    Storage.setOcrPassword(e.target.value);
  };

  const handleFocus = (e: any) => {
    e.target.select();
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    async onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      const file = e.dataTransfer.files[0];

      getBase64(file as RcFile, async url => {
        setBase64Text(url);

        messageApi.open({
          type: "loading",
          content: "图片内容识别中...",
          duration: 0,
        });

        const resp = await ocrRead(password, url);
        messageApi.destroy();
        console.log(resp);
        if (resp.message !== "ok") {
          message.error(resp.message);
          return;
        }

        const results = resp["words_result"];
        if (results?.length) {
          setOutText(results.map((r: any) => r.words).join("\n"));
        }

        message.success("识别完成");
      });
    },
  };

  const clear = () => {
    setBase64Text("");
    setOutText("");
  };

  return (
    <>
      <div style={{ width: "200px", marginBottom: "20px" }}>
        <Input.Password
          value={password}
          autoComplete="false"
          placeholder="此功能需要验证密码"
          onChange={onChangePassword}
        />
      </div>
      <div className="three-col">
        <div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖拽文件到此区域</p>
            <p className="ant-upload-hint">仅支持单个文件上传，请上传 png 格式图片</p>
          </Dragger>
        </div>

        <div>
          <TextArea
            rows={9}
            value={base64Text}
            placeholder="上传图片生成 Base64..."
            spellCheck={false}
            onFocus={handleFocus}
          />
        </div>

        <div>
          <TextArea
            rows={9}
            value={outText}
            placeholder="上传图片识别内容..."
            spellCheck={false}
            onFocus={handleFocus}
          />
        </div>
      </div>
      <div style={{ marginTop: "10px", textAlign: "right" }}>
        <Space>
          <Button type="primary" onClick={clear}>
            清空
          </Button>
        </Space>
      </div>
    </>
  );
}
