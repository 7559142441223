import { useState } from "react";
import { Input, Button, Space } from "antd";
import { encode, decode } from "js-base64";

const { TextArea } = Input;

export default function Base64() {
  const [srcText, setSrcText] = useState("");
  const [encText, setEncText] = useState("");

  const handleEncode = () => {
    if (!srcText.trim()) return;

    setEncText(encode(srcText.trim()));
  };
  const handleDecode = () => {
    if (!encText.trim()) return;

    setSrcText(decode(encText.trim()));
  };
  const handleChangeSrcText = (e: any) => {
    setSrcText(e.target.value);
  };
  const handleChangeEncText = (e: any) => {
    setEncText(e.target.value);
  };

  return (
    <>
      <div className="two-col">
        <div>
          <TextArea
            rows={10}
            value={srcText}
            placeholder="原始内容"
            spellCheck={false}
            onChange={handleChangeSrcText}
          />
        </div>
        <div>
          <TextArea
            rows={10}
            value={encText}
            placeholder="加密内容"
            spellCheck={false}
            onChange={handleChangeEncText}
          />
        </div>
      </div>
      <div className="two-col-btn">
        <Space>
          <Button type="primary" onClick={handleEncode}>
            加密
          </Button>
          <Button onClick={handleDecode}>解密</Button>
        </Space>
      </div>
    </>
  );
}
