import { API_HOST } from "../constants";

export async function clash2VMess(data: string) {
  return fetch(`${API_HOST}/api/v2convert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ data }),
  }).then(res => res.json());
}
