import { useState } from "react";
import { Input, Button, Space, message } from "antd";
import copy from "copy-to-clipboard";
import { clash2VMess } from "../../service/vmess.api";

const { TextArea } = Input;

export default function VMess() {
  const [srcText, setSrcText] = useState("");
  const [encText, setEncText] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleChangeSrcText = (e: any) => {
    setSrcText(e.target.value);
  };

  const convert = async () => {
    setLoading(true);
    const res = await clash2VMess(srcText);
    setLoading(false);
    setEncText(res.data);
  };

  const handleFocus = (e: any) => {
    e.target.select();
  };

  const handleCopy = () => {
    if (encText) {
      if (copy(encText)) {
        messageApi.success("Copied~");
      }
    }
  };

  return (
    <>
      {contextHolder}
      <div className="two-col">
        <div>
          <TextArea
            rows={10}
            value={srcText}
            placeholder="节点"
            spellCheck={false}
            onChange={handleChangeSrcText}
            onFocus={handleFocus}
          />
        </div>
        <div>
          <TextArea
            disabled
            rows={10}
            value={encText}
            placeholder="vmess://"
            spellCheck={false}
            onClick={handleFocus}
          />
        </div>
      </div>
      <div className="two-col-btn">
        <Space>
          <Button type="primary" onClick={convert} loading={loading}>
            转换
          </Button>
          <Button onClick={handleCopy}>复制结果</Button>
        </Space>
      </div>
    </>
  );
}
