export class Storage {
  prefix = "web-tools-";

  setOcrPassword(pwd: string) {
    localStorage.setItem(this.prefix + "ocr-pwd", pwd);
  }
  getOcrPassword() {
    return localStorage.getItem(this.prefix + "ocr-pwd") || "";
  }
}

const s = new Storage();
export default s;
