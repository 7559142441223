import { useState } from "react";
import { useQRCode } from "next-qrcode";
import { Input, Button, Space } from "antd";

const { TextArea } = Input;

export default function QRCode() {
  const [srcText, setSrcText] = useState("输入文本内容");
  const [qrcodeText, setQRCodeText] = useState(srcText);
  const [bgColor, setBgColor] = useState("#ffffff"); // 背景色
  const [fgColor, setFgColor] = useState("#000000"); // 前景色
  const { Canvas } = useQRCode();

  const handleChangeSrcText = (e: any) => {
    setSrcText(e.target.value);
  };
  const handleFocus = (e: any) => {
    e.target.select();
  };
  const handleSubmit = () => {
    setQRCodeText(srcText);
  };
  const handleChangeFgColor = (e: any) => {
    setFgColor(e.target.value);
  };
  const handleChangeBgColor = (e: any) => {
    setBgColor(e.target.value);
  };

  return (
    <>
      <div className="two-col">
        <div>
          <TextArea
            rows={9}
            value={srcText}
            placeholder="文本内容"
            spellCheck={false}
            onChange={handleChangeSrcText}
            onFocus={handleFocus}
          />
        </div>
        <div>
          <div className="qrcode-img">
            <Canvas
              text={qrcodeText}
              options={{
                level: "M",
                margin: 3,
                scale: 4,
                width: 200,
                color: { dark: fgColor, light: bgColor },
              }}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Space>
          <Button type="primary" onClick={handleSubmit}>
            生成
          </Button>

          <div style={{ marginLeft: "30px" }}>
            <span>前景色：</span>
            <input type="color" value={fgColor} onChange={handleChangeFgColor} />
            <span style={{ marginLeft: "10px" }}>背景色：</span>
            <input type="color" value={bgColor} onChange={handleChangeBgColor} />
          </div>
        </Space>
      </div>
    </>
  );
}
