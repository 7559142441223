import { useState } from "react";
import { Input, Button, Space, message } from "antd";
import copy from "copy-to-clipboard";

const { TextArea } = Input;

export default function VMess() {
  const [srcText, setSrcText] = useState("");
  const [encText, setEncText] = useState("");
  const [fastGitText, setFastGitText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const handleChangeSrcText = (e: any) => {
    setSrcText(e.target.value);
  };

  const convert = async () => {
    const urls = srcText.split("\n");
    setEncText(urls.map(ghRaw2jsdelivr).join("\n"));
    setFastGitText(urls.map(ghRaw2fastgit).join("\n"));
  };

  const handleFocus = (e: any) => {
    // e.target.select();
  };

  const handleCopy = () => {
    if (encText) {
      if (copy(encText)) {
        messageApi.success("Copied~");
      }
    }
  };

  function ghRaw2jsdelivr(rawUrl: string) {
    const arr = rawUrl.match(/(raw.githubusercontent.com)\/([a-zA-Z0-9]+)\/(\w+)\/(\w+)\/(.*)/);
    const user = arr?.[2];
    const repo = arr?.[3];
    const branch = arr?.[4];
    const path = arr?.[5];

    return `https://cdn.jsdelivr.net/gh/${user}/${repo}@${branch}/${path}`;
  }
  function ghRaw2fastgit(rawUrl: string) {
    return rawUrl.replace("raw.githubusercontent.com", "raw.fastgit.org");
  }

  return (
    <>
      {contextHolder}
      <div className="three-col">
        <div>
          <TextArea
            rows={10}
            value={srcText}
            placeholder="示例：https://raw.githubusercontent.com/jquery/jquery/main/src/ajax.js (可输入多行)"
            spellCheck={false}
            onChange={handleChangeSrcText}
            onFocus={handleFocus}
          />
        </div>
        <div>
          <TextArea
            disabled
            rows={10}
            value={encText}
            placeholder="Jsdelivr URL"
            spellCheck={false}
            onClick={handleFocus}
          />
        </div>
        <div>
          <TextArea
            disabled
            rows={10}
            value={fastGitText}
            placeholder="fastgit URL"
            spellCheck={false}
            onClick={handleFocus}
          />
        </div>
      </div>
      <div className="two-col-btn">
        <Space>
          <Button type="primary" onClick={convert}>
            转换
          </Button>
          <Button onClick={handleCopy}>复制结果</Button>
        </Space>
      </div>
    </>
  );
}
