import { useState } from "react";
import { Input, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps, UploadFile } from "antd/es/upload/interface";
import BarcodeDetector from "barcode-detector";

const { TextArea } = Input;
const { Dragger } = Upload;
const barcodeDetector = new BarcodeDetector({ formats: ["qr_code"] });

export default function QRCodeReader() {
  const [outputText, setOutputText] = useState("");

  const handleFocus = (e: any) => {
    e.target.select();
  };

  const parseFile = async (file: UploadFile | File) => {
    const barcodes = await barcodeDetector.detect(file);
    setOutputText(barcodes.map((code: any) => code.rawValue).join("\n"));
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange(info) {
      if (info.file) {
        parseFile(info.file);
      }
    },
    async onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
      const file = e.dataTransfer.files[0];
      parseFile(file);
    },
  };

  return (
    <>
      <div className="two-col">
        <div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">点击或拖拽文件到此区域</p>
            <p className="ant-upload-hint">仅支持单个文件上传，请上传 png 格式图片</p>
          </Dragger>
        </div>

        <div>
          <TextArea
            rows={9}
            value={outputText}
            placeholder="上传二维码识别内容..."
            spellCheck={false}
            onFocus={handleFocus}
          />
        </div>
      </div>
      {/*
      <div style={{ marginTop: "10px" }}>
        <Space>
          <Button type="primary" onClick={handleSubmit}>
            识别
          </Button>
        </Space>
      </div>
      */}
    </>
  );
}
