import React, { useState } from "react";
import { Alert, Input, Button, InputNumber, Space } from "antd";

const { TextArea } = Input;

export default function URLEncode() {
  const [kbps, setKbps] = useState(1000);
  const [mbps, setMbps] = useState(1);
  const [speedKB, setSpeedKB] = useState(125);

  const changeKbps = (v: number | null) => {
    if (v !== null) {
      setKbps(v);
      setMbps(v / 1000);
      setSpeedKB(kbps2KBps(v));
    }
  };
  const changeMbps = (v: number | null) => {
    if (v !== null) {
      setMbps(v);
      setKbps(v * 1000);
      setSpeedKB(kbps2KBps(v * 1000));
    }
  };
  const changeSpeedKB = (v: number | null) => {
    if (v !== null) {
      setSpeedKB(v);
      setKbps(v * 8);
      setMbps((v * 8) / 1000);
    }
  };

  const selectAll = (e: any) => {
    e.target.select();
  };

  function kbps2KBps(v: number) {
    return Number((v / 8).toFixed(2));
  }

  function renderMessageContent() {
    return (
      <div>
        <div>
          <span>
            <code>kbps</code>：千比特每秒(<code>Kilobit per second</code>)；
          </span>
          <span style={{ color: "#aaa" }}>
            <code>kBps</code>：千字节/秒(<code>Kilobyte per second</code>, 大写 B)；
          </span>
        </div>
        <div>
          1000 Kbps = 1 Mbps; 1000 Kbps ≈ 125 KB/s; <span style={{ color: "#aaa" }}>1 Megabit = 1,000 Kilobits; </span>
        </div>
        <div style={{ color: "#aaa", marginTop: "10px" }}>
          ref: <a href="https://en.wikipedia.org/wiki/Data-rate_units">https://en.wikipedia.org/wiki/Data-rate_units</a>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "700px", margin: "auto" }}>
      <Space direction="vertical" size="middle" align="center" style={{ display: "flex" }}>
        <Alert message={renderMessageContent()} type="info" closable />

        <h1>YouTube 速率转换</h1>

        <div>
          <InputNumber
            style={{ width: "500px" }}
            addonAfter="Kbps"
            value={kbps}
            onChange={changeKbps}
            onFocus={selectAll}
          />
        </div>
        <div>
          <InputNumber
            style={{ width: "500px" }}
            addonAfter="Mbps"
            value={mbps}
            onChange={changeMbps}
            onFocus={selectAll}
          />
        </div>
        <div>
          <InputNumber
            style={{ width: "500px" }}
            addonAfter="KB/s"
            value={speedKB}
            onChange={changeSpeedKB}
            onFocus={selectAll}
          />
        </div>
      </Space>
    </div>
  );
}
